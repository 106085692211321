@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url("./fonts/Arsenal-Regular.ttf") format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(87 37 68) !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  font-weight: bold;
  color: black;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 220px;
}

.react-datepicker {
  /* background-color: rgb(87 37 68) !important; */
  color: #fff !important;
  opacity: 1;
  position: relative !important;
}

.react-datepicker__input-container > input {
  padding-left: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 5px;
  border-color: lightgray;
  border-width: 1px;
  width: 200px;
}
